/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getLocaldata } from "../../Components/Shared/LocalStorage";
import AllCart from "./AllCart";
import useAuth from "../../Hooks/UseAuth";
import { useForm } from "react-hook-form";
import Modal from "../../Components/PmentPage/Modal";
import PrivateRout from "../../routes/PrivateRoute";

const Cart = () => {
  const { pathname } = useLocation();
  const { DLoading, setDLoading , user } = useAuth();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [dataUp , setDataUp] = useState(false)

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState([]);
  // const [totalPrice, setTotalPrice] = useState(1800);

  useEffect(() => {
    const product = getLocaldata();
    setProducts(product);
  }, [DLoading]);

  useEffect(() => {
    const product = getLocaldata();
    setProduct(product);
  }, [DLoading, dataUp]);


  const tp = product.reduce((sum, product) => sum + parseFloat(product.price), 0);
  const totalPrice = Math.ceil(tp);



  return (
    <div>
     

      <Modal totalPrice={totalPrice} products={products} />

      {product.length> 0 ?     <div>
        <div className="max-w-md gap-4 py-8 mx-auto rounded-md bg-cardBg text-center">
          <h1 className="mb-5">
            {" "}
            <span className="font-bold text-black">Total Amount : </span>{" "}
            <span className="text-black">{totalPrice}</span>
          </h1>
         
          {user?.uid  ? <button className="bg-yellow-400 hover:bg-buttonHoverColor  px-4 py-2 text-black rounded-lg" onClick={() => {
            document.getElementById('my_modal_5').showModal()
              
          }
          }>             Buy Now
          </button> : <Link to='/login' className="bg-yellow-400 hover:bg-buttonHoverColor  px-4 py-2 text-black rounded-lg" onClick={() => {
            document.getElementById('my_modal_5').showModal()
              
          }
          }>             Buy Now
          </Link>}

          
         


          {/* <button onClick={handelPayment} className="bg-yellow-400 hover:bg-buttonHoverColor  px-4 py-2 text-black rounded-lg">
          </button> */}
        </div>
      </div> :  <div><h1 className="text-center my-8">Goo to shop page ....</h1></div>}

      <div className="mx-auto">
        {products.map((data, i) => (
          <AllCart key={i + 1} datas={data} setDataUp={setDataUp} dataUp={dataUp} />
        ))}
      </div>
    </div>
  );
};

export default Cart;
