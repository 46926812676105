import MainLayout from './layouts/MainLayout'
import CompanyIcon from "./assets/whatsapp.svg"
import { Link } from 'react-router-dom'

const App = () => {

  return (
    <>
      <div className="max-w-[1350px] mx-auto">
        <MainLayout />
      </div>
      <div className='fixed bottom-5 right-5 z-50 w-20h-16  rounded-full border-green-600'>
      </div>
      <div className='fixed bottom-5 right-5 z-50 w-32 h-28  rounded-full border-green-600'>
         <Link to='/shop' className='bg-yellow-500 hover:bg-yellow-400 text-black px-2 py-2 rounded-md'>Shop Now</Link>
        <h1 className='h-2'>
          
        </h1>

        <a href="https://wa.me/7872282450?text=Hello" target='_blank'>
          <img src={CompanyIcon} alt="WhatsApp" className='w-12 h-12' />
        </a>
       
      </div>
    </>
  )
}

export default App
