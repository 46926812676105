/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  deleteDta,
  getLocaldata,
  saveLocalData,
} from "../../Components/Shared/LocalStorage";
import useAuth from "../../Hooks/UseAuth";

const AllCart = ({ datas, dataUp, setDataUp }) => {
  const {
    _id,
    image,
    name,
    size,
    quantity,
    price,
    originalPrice,
    customizeTexts,
    customizeImage,
    discount,
  } = datas;
  const { DLoading, setDLoading, maulLoading, setMaulLoading } = useAuth();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [quantityOfNumber, setQuantityOfNumber] = useState(quantity);

  const CountPlas = (id, size) => {
    const updatedProducts = products.filter(
      (product) => !(product.size === size && product._id === id)
    );
    localStorage.setItem("product", JSON.stringify(updatedProducts));
    setQuantityOfNumber(quantityOfNumber + 1);
  };
  const CountMinas = (id, size) => {

    if (quantityOfNumber > 1) {
          const updatedProducts = products.filter(
      (product) => !(product.size === size && product._id === id)
    );
    localStorage.setItem("product", JSON.stringify(updatedProducts));
      setQuantityOfNumber(quantityOfNumber - 1);
    }
  };

  const calculateTotal = (
    originalPrice,
    quantityOfNumber,
    gstRate,
    discount
  ) => {
    const t = quantityOfNumber * originalPrice;
    const p = t + (t * gstRate) / 100;
    if (discount > 0) {
      const l = p - (p * discount) / 100;
      return l
    }
    return p;
  };
  const total = calculateTotal(originalPrice, quantityOfNumber, 18, discount)
  const totalAmount = Math.ceil(total);

  useEffect(() => {
    const localPostdata = {
      _id,
      name,
      image,
      originalPrice: originalPrice,
      price: totalAmount,
      quantity: quantityOfNumber,
      size,
      customizeTexts,
      customizeImage,
    };
    saveLocalData(localPostdata, _id, size);
    setDataUp(!dataUp);
  }, [totalAmount, quantityOfNumber]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const storedProducts = getLocaldata();
    setProducts(storedProducts);
  }, [DLoading, maulLoading]);

  const deleteProductById = (id, size) => {
    const updatedProducts = products.filter(
      (product) => !(product.size === size && product._id === id)
    );
    localStorage.setItem("product", JSON.stringify(updatedProducts));
    setDLoading(!DLoading);
    setMaulLoading(!maulLoading);
  };

  return (
    <div className="border-b-2 border-black p-4   xl:flex lg:flex md:flex  gap-4 max-w-[850px] mx-auto justify-between">
      <Link to={`/productDetails/${_id}`}>
      <div className="avatar">
        <div className="w-28 rounded">
          <img src={image} alt="" />
        </div>
        </div>
        </Link>
      <div>
        <h1 className="text-black text-2xl">{name}</h1>
        <h1 className="text-black text-xl">
          <span>Price : </span>
          {totalAmount}
        </h1>
        <h1 className="text-black text-xl">
          <span>Size : </span>
          {size}
        </h1>
        <div className="flex">
          <h1 className="text-xl text-black">Quantity :</h1>
          <div className="border-2 flex justify-between p-1 rounded-md ">
            <button
              onClick={() => CountMinas(_id, size)}
              className=" bg-yellow-500 hover:bg-yellow-600 px-5 mr-3 text-black rounded-md "
            >
              -
            </button>
            <h4 className="text-black">{quantityOfNumber}</h4>
            <button
              onClick={() => CountPlas(_id, size)}
              className=" bg-yellow-500 hover:bg-yellow-600 px-5 ml-3 text-black rounded-md "
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div className="items-center ">
        <button
          onClick={() => {
            deleteProductById(_id, size);
          }}
          className="bg-red-400 hover:bg-red-500  px-4 py-2 text-black rounded-lg my-5"
        >
          Delete
        </button>
      </div>
      </div>
    
  );
};

export default AllCart;
